<template>
    <div class="back">
        <v-container class="mt-16 mb-16 flex justify-center">
            <div class="flex flex-wrap justify-center">
                <v-card
                    v-for="(item, index) in iglesias"
                    :key="index"
                    class="directorio-card"
                    @click="openWeb(item.Nombre_Id)"
                >
                    <div class="d-flex justify-center">
                        <img :src="item.Logo" alt="" />
                    </div>
                    <v-card-title primary-title class="d-flex justify-center">
                        {{ item.Nombre }}
                    </v-card-title>
                </v-card>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            iglesias: [],
        };
    },
    created() {
        this.$store.dispatch("get_Iglesias").then((res) => {
            this.iglesias = res;
        });
    },
    methods:{
      openWeb(nombre){
        window.open(`http://abcchurchs.com/#/${nombre}`, "_self")
      }
    }
};
</script>

<style lang="scss" scoped>
.back {
    width: 100%;
    height: 100vh;
    background-color: black;
    border: gray 2px solid;
    overflow-y: auto;
}
.directorio-card {
    // background-color: blue !important;
    border: #1fed46 3px solid !important;
    width: 300px !important;
    margin: 5px;
    transition: 0.3s;
    img {
        width: 300px;
    }
    &:hover {
        background-color: #1fed46;
        color:white;
        transform: scale(1.2);
        z-index: 100;
        margin: 50px;
        
    }
}
</style>
